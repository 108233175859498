import type { UserInfo } from './user-info.interface';

export const useUserInfoStore = defineStore('userInfo', () => {
   const userInfo = ref<UserInfo | null>(null);
   const loadingUserInfo = ref(false);

   function changeUserPerson(person: Partial<Person>) {
      if (!userInfo.value?.person) return;

      userInfo.value.person = { ...userInfo.value?.person, ...person };
   }

   async function getUserInfo() {
      try {
         const { $sentrySetUser, $clientPosthog } = useNuxtApp();
         loadingUserInfo.value = true;

         const response = await authFetch<UserInfo>(USER_INFO_ENDPOINTS.GET());
         userInfo.value = response;

         if (userInfo.value) {
            $sentrySetUser({
               email: response?.email,
               username: `${response?.first_name} ${response?.last_name}`,
               sync_id: userInfo.value?.sync_id,
            });

            $clientPosthog?.identify(userInfo.value.email, {
               email: userInfo.value.email,
               id: userInfo.value.sync_id,
               first_name: userInfo.value.first_name,
               last_name: userInfo.value.last_name,
            });
         }

         return response;
      } catch {
         console.warn(`Could not request data`);
      } finally {
         loadingUserInfo.value = false;
      }
   }

   return { userInfo, loadingUserInfo, getUserInfo, changeUserPerson };
});
