<script lang="ts" setup>
import { storeToRefs } from 'pinia';

// #region Globals
const isTablet = useMediaQuery(TABLET_AND_UP);
const { showPositions, showDocuments, appImageBasePath } = useBranding();
// #endregion

// #region Props
defineProps<{
   fullNavigation?: boolean;
}>();
// #endregion

// #region Tasks
const myWeekStore = useMyWeekStore();
const { myWeek } = storeToRefs(myWeekStore);

const amountOfTasks = computed(() => myWeek.value?.my_tasks?.length ?? 0);
// #endregion

// #region User Info
const userInfoStore = useUserInfoStore();
const { userInfo } = storeToRefs(userInfoStore);

const profilePicutureUrl = computed(() => userInfo.value?.person?.profile_picture_url ?? '');
const fullName = computed(() => `${userInfo.value?.person?.first_name ?? ''} ${userInfo.value?.person?.last_name ?? ''}`);
const isProfilePage = computed(() => useRoute().name?.toString().startsWith('profile'));
// #endregion

// #region Show More Menu
const showShowMoreMenu = ref(false);
// #endregion``
</script>

<template>
   <nav v-if="!isTablet" class="navigation">
      <div class="navigation__items">
         <LayoutNavigationItem
            :class="'navigation__items__item'"
            :to="`/`"
            :icon-name="'house'"
            :label="$t('my-week.start')"
            :badge-count="amountOfTasks"
         />

         <LayoutNavigationItem
            :class="'navigation__items__item'"
            :to="'/schedule'"
            :icon-name="'calendar-days'"
            :label="$t('schedules.schedule')"
         />

         <LayoutNavigationItem
            :class="'navigation__items__item'"
            :to="`/time-registration`"
            :icon-name="'qrcode'"
            :label="$t('time-registrations.time-registration')"
         />

         <LayoutNavigationItem
            v-if="showPositions"
            :class="'navigation__items__item'"
            :icon-name="'ellipsis'"
            :label="$t('general.more')"
            @click="showShowMoreMenu = true"
         />

         <LayoutNavigationItem v-else :class="'navigation__items__item -profile'" :to="`/profile`" :label="$t('profile.profile')">
            <LayoutCircularLogo
               v-if="userInfo"
               :height="isProfilePage ? 40 : 45"
               :width="isProfilePage ? 40 : 45"
               :name="fullName"
               :logo="profilePicutureUrl"
               :object-fit="'cover'"
            />
         </LayoutNavigationItem>
      </div>

      <Teleport to="body">
         <LayoutNavigationShowMoreMenu v-if="showShowMoreMenu" @on-close="showShowMoreMenu = false" />
      </Teleport>
   </nav>

   <nav v-else class="navigation">
      <div :class="'navigation__logo'">
         <img :src="`/${appImageBasePath}/emblem.png`" :alt="'NxtPeople'" />
      </div>

      <LayoutNavigationItem
         :class="'navigation__items__item'"
         :to="`/`"
         :badge-count="amountOfTasks"
         :icon-name="'house'"
         :label="$t('my-week.start')"
      />

      <LayoutNavigationItem
         :class="'navigation__items__item'"
         :to="`/time-registration`"
         :icon-name="'qrcode'"
         :label="$t('time-registrations.time-registration')"
      />

      <LayoutNavigationItem
         :class="'navigation__items__item'"
         :to="'/schedule'"
         :icon-name="'calendar-days'"
         :label="$t('schedules.schedule')"
      />

      <LayoutNavigationItem
         v-if="showDocuments"
         :class="'navigation__items__item'"
         :to="`/documents`"
         :icon-name="'files'"
         :label="$t('documents.document', 2)"
      />

      <template v-if="showPositions">
         <LayoutNavigationItem
            :class="'navigation__items__item'"
            :to="'/jobs-and-applications/jobs'"
            :icon-name="'jobs'"
            :icon-style="'fa-kit'"
            :label="$t('jobs.job', 2)"
         />
         <LayoutNavigationItem
            :class="'navigation__items__item'"
            :to="'/jobs-and-applications/job-applications'"
            :icon-name="'square-pen'"
            :label="$t('jobs.applications.application', 2)"
         />
      </template>

      <LayoutNavigationItem :class="'navigation__items__item -profile'" :to="`/profile`" :label="$t('profile.profile')">
         <LayoutCircularLogo v-if="userInfo" :height="45" :width="45" :name="fullName" :logo="profilePicutureUrl" :object-fit="'cover'" />
      </LayoutNavigationItem>
   </nav>
</template>

<style lang="scss" scoped>
.navigation {
   position: relative;
   background: rgba(255, 255, 255, 0.5);
   backdrop-filter: blur(0.4rem);
   box-shadow: 0 0 1rem 1rem rgba(255, 255, 255, 0.5);
   padding-bottom: env(safe-area-inset-bottom);

   @include tablet {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--nxt-gutter-large);
      height: unset;

      backdrop-filter: none;
      background-color: var(--nxt-base-header-background);
      box-shadow: 2px 0px 4px 0px #00000026;
      z-index: calc(var(--zindex-fixed) + 1);
      width: var(--nxt-navigation-width);
      padding: var(--nxt-gutter);
      padding-bottom: var(--nxt-gutter) !important;
   }

   > * {
      &:last-child {
         @include tablet {
            margin-top: auto;

            &:deep(.navigation-item__label) {
               display: none;
            }
         }
      }
   }
}

.navigation__logo {
   display: flex;
   justify-content: center;
   align-items: center;
   margin-bottom: var(--nxt-gutter);

   img {
      max-width: 90%;
   }
}

.navigation__items {
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   gap: var(--nxt-gutter-small);
   padding: var(--nxt-gutter-small);
   z-index: 1;
}
</style>
