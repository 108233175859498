<script lang="ts" setup>
import type { StyleValue } from 'vue';

defineEmits<{ (e: 'onClick'): void }>();

defineProps({
   iconName: { type: String, default: null }, // fontawesome icon name
   iconType: { type: String, default: 'solid' },
   iconStyle: { type: Object as PropType<StyleValue>, default: () => ({}) },
   iconLabel: { type: String, default: null },
   iconPosition: { type: String as PropType<'left' | 'right'>, default: 'right' },
   disabled: { type: Boolean, default: false },
   color: { type: String, default: 'var(--nxt-secondary)' },
   textAlign: { type: String as PropType<'start' | 'end' | 'center'>, default: 'center' },

   type: { type: String as PropType<'button' | 'submit' | 'reset'>, default: 'button' },
});

const { handleClick } = usePreventDoubleClick();
</script>

<template>
   <button
      :type="type"
      :class="['button-link', { icon: !!iconName }]"
      :disabled="disabled"
      @click="type === 'button' ? handleClick(() => $emit('onClick')) : undefined"
   >
      <i
         v-if="iconName && iconPosition === 'left'"
         :class="[`fa-${iconType}`, `fa-${iconName}`, 'button-link__icon']"
         :style="iconStyle"
         :aria-label="iconLabel"
      ></i>
      <span>
         <slot></slot>
      </span>
      <i
         v-if="iconName && iconPosition === 'right'"
         :class="[`fa-${iconType}`, `fa-${iconName}`, 'button-link__icon']"
         :style="iconStyle"
         :aria-label="iconLabel"
      ></i>
   </button>
</template>

<style lang="scss" scoped>
.button-link {
   background-color: transparent;
   border: none;
   color: v-bind(color);
   padding: 0;
   display: flex;
   align-items: center;
   justify-content: v-bind(textAlign);
   padding: var(--nxt-gutter-small);
   text-align: v-bind(textAlign);

   &:not(:disabled):hover {
      cursor: pointer;
   }

   &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
   }

   &.icon {
      gap: var(--nxt-gutter-small);
   }
}

.button-link__icon {
   font-size: toRem(16);
}
</style>
