<script lang="ts" setup>
import SwiperInstance from 'swiper';
import FormContainer from '~/components/form/Container.vue';
import type { SwiperWeek } from '~/models/swiper-schedule.interface';
import { AVAILABILITY_TODO_ENDPOINTS } from '~/resources/availability-todo/availability-todo.endpoints';
import { useAvailabilityStore } from '~/resources/schedule/availability/availability.store';

// #region Globals
const dayjs = useDayjs();
const swiperInstance = inject<Ref<SwiperInstance>>('swiperInstance');
const saving = ref(false);
// #endregion

// #region Props & Emits
const emits = defineEmits<{ (e: 'onClose'): void; (e: 'updateOtherWeeks'): void }>();
const props = defineProps({
   isActiveWeek: { type: Boolean, default: false },
   availabilityTodo: { type: Object as PropType<AvailabilityTodo>, default: null },
   period: { type: Object as PropType<SwiperWeek>, required: true },
});

const periodDiff = computed(() => dayjs(props.period.end).diff(dayjs(props.period.start), 'day'));
const days = computed(() => [...Array(periodDiff.value + 1)].map((_, i) => dayjs(props.period.start).add(i, 'day')));
// #endregion

// #region Form Control
const formRef = ref();
const { requiredIf, minDate } = useFormValidators({ baseValidationPath: 'schedules.availabilities' });

const { data, validation } = useForm<AvailabilityBatch>({
   config: {
      initialValues: {
         availabilities: days.value.map((day) => ({ date: day.format('YYYY-MM-DD') })),
         repetition_frequency: undefined,
         repeat: false,
      },
   },
   rules: (data) => ({
      repetition_frequency: {
         is_two_weekly: { requiredIf: requiredIf(() => !!data.repeat) },
         end_date: { requiredIf: requiredIf(() => !!data.repeat), minDate: minDate(props.period.end.format('YYYY-MM-DD')) },
      },
   }),
});
// #endregion

// #region Availabilities

function initAvailabilities(week: SwiperWeek) {
   const schedule = week.schedule;
   if (!schedule) return;

   const scheduleDays = Object.entries(schedule) as any;

   data.value.availabilities = days.value.map((day) => {
      const scheduleDay = scheduleDays.find(([date]: any) => dayjs(date).isSame(day, 'day'))?.[1];
      const date = day.format('YYYY-MM-DD');
      if (!scheduleDay?.availability) return { date };

      return { ...scheduleDay.availability, date };
   });
}

watch(() => props.period, initAvailabilities, { immediate: true, deep: true });
// #endregion

// #region Repetition
const repetitionFrequency = computed(() => data.value.repetition_frequency);

async function updateAvailabilitiesIfRepetitionIsEnabled() {
   if (!data.value.repetition_frequency?.end_date || data.value.repetition_frequency.is_two_weekly === undefined) {
      return;
   }
   await validation.value.$validate();

   if (validation.value.$invalid) {
      return;
   }
   if (data.value.repetition_frequency?.end_date && data.value.repetition_frequency?.is_two_weekly !== undefined) {
      updateAvailabilitiesBatch({ availabilities: data.value.availabilities });
   }
}

async function repetitionChanged(repeat?: boolean) {
   if (!repeat) {
      data.value.repetition_frequency = undefined;
   } else {
      data.value.repetition_frequency = { is_two_weekly: false, end_date: '' };
   }

   await nextTick();
   swiperInstance?.value.update();
}

watch(repetitionFrequency, updateAvailabilitiesIfRepetitionIsEnabled, { deep: true });
watch(() => data.value.repeat, repetitionChanged);
// #endregion

// #region All Week Available
const isAvailableAllWeek = ref<boolean>();
const showUnavailabilityModal = ref(false);

watch(isAvailableAllWeek, updateWeekAvailabilities);

function changeAvailabilities(isAvailableAllWeek?: boolean) {
   const newAvailabilities: Array<Availability> = data.value.availabilities.map((availability: Availability) => {
      const isAfterToday = dayjs(availability.date).isAfter(dayjs(), 'day');
      return isAfterToday
         ? {
              ...availability,
              is_available: isAvailableAllWeek,
              start_time: isAvailableAllWeek ? undefined : availability.start_time,
              end_time: isAvailableAllWeek ? undefined : availability.end_time,
           }
         : availability;
   });
   updateAvailabilitiesBatch({ availabilities: newAvailabilities });
}

function updateWeekAvailabilities(isAvailableAllWeek?: boolean) {
   if (isAvailableAllWeek === undefined) return;

   const hasDayWithPlanning = data.value.availabilities.find((a: Availability) => !!props.period?.schedule?.[a.date]?.planning);
   if (!isAvailableAllWeek && hasDayWithPlanning) {
      return (showUnavailabilityModal.value = true);
   } else {
      return changeAvailabilities(isAvailableAllWeek);
   }
}

function applyUnavailability(availability: Availability) {
   data.value.availabilities = data.value.availabilities.map((a: Availability) => ({
      ...a,
      is_available: false,
      start_time: undefined,
      end_time: undefined,
      unavailability_type_id: availability?.unavailability_type_id,
      unavailability_type: availability?.unavailability_type,
      unavailability_reason: availability?.unavailability_reason,
   }));
   changeAvailabilities(false);
   showUnavailabilityModal.value = false;
}
// #endregion

// #region Change Availability

// Update Day
async function updateAvailability(availability: Availability) {
   const index = data.value.availabilities.findIndex((a: Availability) => a?.date === availability.date);
   if (index === -1) {
      data.value.availabilities.push(availability);
   } else {
      data.value.availabilities[index] = availability;
   }

   if (swiperInstance?.value) swiperInstance.value.update();

   await useAvailabilityStore()
      .postAvailability(availability)
      .catch(() => {
         console.warn('Could not update availability');
      });

   if (availability.repetition_frequency) {
      emits('updateOtherWeeks');
   }
}

// Update All
async function updateAvailabilitiesBatch(batch: AvailabilityBatch) {
   data.value.availabilities = JSON.parse(JSON.stringify(batch.availabilities));

   const availabilitiesBatch: AvailabilityBatch = {
      ...batch,
      availabilities: batch.availabilities
         .filter((availability: Availability) => availability.is_available !== undefined)
         .filter((availability: Availability) => dayjs(availability.date).isAfter(dayjs(), 'day')),
   };

   if (data.value.repetition_frequency?.end_date !== undefined && data.value.repetition_frequency?.is_two_weekly !== undefined) {
      availabilitiesBatch.repetition_frequency = data.value.repetition_frequency;
   }

   await useAvailabilityStore()
      .postAvailabilitiesBatch(availabilitiesBatch)
      .catch(() => {
         console.warn('Could not update availabilities');
      });

   if (swiperInstance?.value) swiperInstance.value.update();

   if (data.value.repetition_frequency?.end_date !== undefined && data.value.repetition_frequency?.is_two_weekly !== undefined) {
      emits('updateOtherWeeks');
   }
}

// Availability todo
async function confirmAvailabilityTodo(batch: AvailabilityBatch) {
   const availabilitiesBatch: AvailabilityBatch = {
      ...batch,
      availabilities: batch.availabilities
         .filter(filterAvailabilityHasPassed)
         .filter((availability: Availability) => availability.is_available !== undefined),
   };

   await authFetch(AVAILABILITY_TODO_ENDPOINTS.CONFIRM(props.availabilityTodo.sync_id), { method: 'POST', body: availabilitiesBatch }).then(
      () => {
         emits('onClose');
      },
   );
}

// On submit
function filterAvailabilityHasPassed(availability: Availability) {
   return dayjs(availability.date).isAfter(dayjs(), 'day');
}

const submitDisabled = computed(() => {
   if (saving.value) return true;
   if (!props.availabilityTodo) return false;
   const inputtedDays = data.value.availabilities.filter((availability: Availability) => availability.is_available !== undefined);

   const availabilitiesWithoutPlanningApproved = data.value.availabilities.filter(filterAvailabilityHasPassed);

   if (inputtedDays.length < availabilitiesWithoutPlanningApproved.length) return true;

   return false;
});

async function updateAvailabilities(availabilitiesBatch: AvailabilityBatch) {
   try {
      saving.value = true;

      if (props.availabilityTodo) await confirmAvailabilityTodo(availabilitiesBatch);
      else await updateAvailabilitiesBatch(availabilitiesBatch);

      emits('onClose');
   } finally {
      saving.value = false;
   }
}
// #endregion
</script>

<template>
   <FormContainer ref="formRef" class="week-availabilities" :data="data" :validation="validation" @on-valid-submit="updateAvailabilities">
      <template v-if="!availabilityTodo">
         <!-- All Week -->
         <LayoutCard v-if="period.start.isAfter(dayjs())" :background-color="'var(--nxt-extralight-grey)'">
            <div class="week-availabilities__all-week">
               <p>
                  {{ $t('schedules.all-week-available') }}
               </p>

               <FormElement :class="'week-availabilities__all-week__toggle'" :name="'available'">
                  <FormButtonToggle v-model="isAvailableAllWeek" />
               </FormElement>
            </div>
         </LayoutCard>

         <!-- Rest of week -->
         <LayoutCard v-else-if="period.end.isAfter(dayjs())">
            <div class="week-availabilities__all-week">
               <p>
                  {{ $t('schedules.rest-of-week-available') }}
               </p>

               <FormElement :name="'available'">
                  <FormButtonToggle v-model="isAvailableAllWeek" />
               </FormElement>
            </div>
         </LayoutCard>
      </template>

      <template v-else-if="availabilityTodo.creator_first_name">
         <AvailabilityTodoHeader
            class="week-availabilities__availability-todo"
            :availability-todo="availabilityTodo"
            :show-period="false"
         />
      </template>

      <!-- Days -->
      <template v-for="availability in data.availabilities" :key="availability.date">
         <ScheduleQuickDayAvailabilityForm
            :class="'week-availabilities__day'"
            :model-value="availability"
            :day-planning="period?.schedule?.[availability.date]?.planning"
            @update:model-value="updateAvailability"
         />
      </template>

      <!-- Repeat week -->
      <div v-if="!availabilityTodo" class="week-availabilities__repetition">
         <FormElement name="repeat" :label="$t('schedules.repeat')" label-position="left" class="week-availabilities__repetition__switch">
            <FormSwitch :model-value="!!data.repeat" @update:model-value="data.repeat = $event" />
         </FormElement>

         <div v-if="data.repeat && data.repetition_frequency" class="week-availabilities__repetition__form">
            <FormElement name="repetition_frequency.is_two_weekly" class="week-availabilities__repetition__dropdown">
               <FormSelect
                  v-model="data.repetition_frequency.is_two_weekly"
                  :options="[
                     { name: $t('schedules.weekly'), value: false },
                     { name: $t('schedules.two-weekly'), value: true },
                  ]"
                  :value-key="'value'"
               />
            </FormElement>
            <FormElement name="repetition_frequency.end_date" :label="$t('schedules.untill-till')" label-position="left">
               <FormDatepicker
                  v-model="data.repetition_frequency.end_date"
                  :min="period.end.add(1, 'day').format('YYYY-MM-DD')"
                  :max="period.end.add(1, 'year').format('YYYY-MM-DD')"
               />
            </FormElement>
         </div>
      </div>

      <ClientOnly>
         <Teleport v-if="showUnavailabilityModal" to=".modal-side__card__content">
            <ScheduleUnavailableForm
               @on-cancel="
                  () => {
                     isAvailableAllWeek = undefined;
                     showUnavailabilityModal = false;
                  }
               "
               @on-save="applyUnavailability"
            />
         </Teleport>
      </ClientOnly>

      <!-- Actions -->
      <ClientOnly>
         <Teleport v-if="isActiveWeek" to=".week-availabilities__modal__actions">
            <div class="week-availabilities__actions">
               <ButtonMain
                  :class="'week-availabilities__actions__submit'"
                  :icon-name="'check'"
                  :background-color="'var(--nxt-main)'"
                  :icon-position="'left'"
                  :disabled="submitDisabled"
                  @on-click="formRef?.handleSubmit()"
               >
                  {{ $t('actions.confirm') }}
               </ButtonMain>

               <p v-if="submitDisabled && !saving">{{ $t('generic.feedback.missing-data') }}</p>
            </div>
         </Teleport>
      </ClientOnly>
   </FormContainer>
</template>

<style lang="scss" scoped>
.week-availabilities {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}

.week-availabilities__all-week {
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
}

.week-availabilities__availability-todo {
   padding-bottom: var(--nxt-gutter);
}

.week-availabilities__day {
   min-height: toRem(30);
}

.week-availabilities__repetition__form {
   display: flex;
   align-items: flex-start;
   gap: var(--nxt-gutter);
   margin-top: var(--nxt-gutter);
}

.week-availabilities__repetition__switch {
   margin-top: var(--nxt-gutter);
   width: 100%;
}

.week-availabilities__repetition__dropdown {
   width: 100%;
}

.week-availabilities__actions {
   display: flex;
   flex-direction: column;
   align-items: center;

   width: 100%;
}

.week-availabilities__actions__submit {
   width: 100%;
}
</style>
