
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as badges3GUbcXxAFMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/badge.vue?macro=true";
import { default as index2ClFvFTWr8Meta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/documents/index.vue?macro=true";
import { default as indexmSdDDH7pj2Meta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/index.vue?macro=true";
import { default as JobsCardkV3t4NeTBOMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/components/cards/JobsCard.vue?macro=true";
import { default as JobsCardLoadinggS571n3TgtMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/components/cards/JobsCardLoading.vue?macro=true";
import { default as JobApplicationSectionvO85srhlVhMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/components/JobApplicationSection.vue?macro=true";
import { default as JobsFilterBarEpYUfbFMriMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/components/JobsFilterBar.vue?macro=true";
import { default as JobsGrid1HQAuHuMTDMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/components/JobsGrid.vue?macro=true";
import { default as JobsGridEmptyFc594kO2XvMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/components/JobsGridEmpty.vue?macro=true";
import { default as JobsGridLoadingTwRwWJRYW1Meta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/components/JobsGridLoading.vue?macro=true";
import { default as JobsApplyModalEZYL4lZ8YEMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/components/modals/JobsApplyModal.vue?macro=true";
import { default as JobsFilterModalbPEtiyRe6AMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/components/modals/JobsFilterModal.vue?macro=true";
import { default as JobsMenuModalcX6Jf4sK6wMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/components/modals/JobsMenuModal.vue?macro=true";
import { default as useApplyToJobF43fK323RdMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/composables/useApplyToJob.ts?macro=true";
import { default as useJobObserverqGlfRAPUyZMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/composables/useJobObserver.ts?macro=true";
import { default as useWageFormattingVOOtsslgmTMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/composables/useWageFormatting.ts?macro=true";
import { default as indexufqCXvpCu3Meta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/index.vue?macro=true";
import { default as indexelFIk6XRhvMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/job-applications/index.vue?macro=true";
import { default as indexG9CQMspQWVMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/jobs/index.vue?macro=true";
import { default as jobs_45filters_46interface2JJRXgjH3ZMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/models/jobs-filters.interface.ts?macro=true";
import { default as jobs_45and_45applicationsV6HAn7elsvMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications.vue?macro=true";
import { default as _91jobId_93tCx0f9jd8bMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs/[jobId].vue?macro=true";
import { default as push_46helperImtemXCYJcMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/my-week/utils/push.helper.ts?macro=true";
import { default as not_45allowed1brvBdTLRbMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/not-allowed.vue?macro=true";
import { default as not_45foundll24F94pkOMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/not-found.vue?macro=true";
import { default as offline93yG0VTps8Meta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/offline.vue?macro=true";
import { default as _91verificationId_930LSNyvJWyoMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/person/[personId]/verify/[verificationId].vue?macro=true";
import { default as privacy_45policyFBFyU0X6RKMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/privacy-policy.vue?macro=true";
import { default as indexxkqlAxAwZoMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/profile/index.vue?macro=true";
import { default as _91_91tenantId_93_93OCBYP4BcB3Meta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/register/[personId]/[[tenantId]].vue?macro=true";
import { default as indexx54pMwIErCMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/register/ensure-candidate/index.vue?macro=true";
import { default as index1VLcMa7kBFMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/register/index.vue?macro=true";
import { default as indexAWzWVS25reMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/schedule/index.vue?macro=true";
import { default as _91timeClockId_938lyU2L7EbMMeta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/time-clock/[timeClockId].vue?macro=true";
import { default as indexiFCqK0Cqz5Meta } from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/time-registration/index.vue?macro=true";
export default [
  {
    name: "badge",
    path: "/badge",
    meta: badges3GUbcXxAFMeta || {},
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/badge.vue")
  },
  {
    name: "documents",
    path: "/documents",
    meta: index2ClFvFTWr8Meta || {},
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/documents/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexmSdDDH7pj2Meta || {},
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/index.vue")
  },
  {
    name: jobs_45and_45applicationsV6HAn7elsvMeta?.name,
    path: "/jobs-and-applications",
    meta: jobs_45and_45applicationsV6HAn7elsvMeta || {},
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications.vue"),
    children: [
  {
    name: "jobs-and-applications-components-cards-JobsCard",
    path: "components/cards/JobsCard",
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/components/cards/JobsCard.vue")
  },
  {
    name: "jobs-and-applications-components-cards-JobsCardLoading",
    path: "components/cards/JobsCardLoading",
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/components/cards/JobsCardLoading.vue")
  },
  {
    name: "jobs-and-applications-components-JobApplicationSection",
    path: "components/JobApplicationSection",
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/components/JobApplicationSection.vue")
  },
  {
    name: "jobs-and-applications-components-JobsFilterBar",
    path: "components/JobsFilterBar",
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/components/JobsFilterBar.vue")
  },
  {
    name: "jobs-and-applications-components-JobsGrid",
    path: "components/JobsGrid",
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/components/JobsGrid.vue")
  },
  {
    name: "jobs-and-applications-components-JobsGridEmpty",
    path: "components/JobsGridEmpty",
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/components/JobsGridEmpty.vue")
  },
  {
    name: "jobs-and-applications-components-JobsGridLoading",
    path: "components/JobsGridLoading",
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/components/JobsGridLoading.vue")
  },
  {
    name: "jobs-and-applications-components-modals-JobsApplyModal",
    path: "components/modals/JobsApplyModal",
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/components/modals/JobsApplyModal.vue")
  },
  {
    name: "jobs-and-applications-components-modals-JobsFilterModal",
    path: "components/modals/JobsFilterModal",
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/components/modals/JobsFilterModal.vue")
  },
  {
    name: "jobs-and-applications-components-modals-JobsMenuModal",
    path: "components/modals/JobsMenuModal",
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/components/modals/JobsMenuModal.vue")
  },
  {
    name: "jobs-and-applications-composables-useApplyToJob",
    path: "composables/useApplyToJob",
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/composables/useApplyToJob.ts")
  },
  {
    name: "jobs-and-applications-composables-useJobObserver",
    path: "composables/useJobObserver",
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/composables/useJobObserver.ts")
  },
  {
    name: "jobs-and-applications-composables-useWageFormatting",
    path: "composables/useWageFormatting",
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/composables/useWageFormatting.ts")
  },
  {
    name: "jobs-and-applications",
    path: "",
    meta: indexufqCXvpCu3Meta || {},
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/index.vue")
  },
  {
    name: "jobs-and-applications-job-applications",
    path: "job-applications",
    meta: indexelFIk6XRhvMeta || {},
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/job-applications/index.vue")
  },
  {
    name: "jobs-and-applications-jobs",
    path: "jobs",
    meta: indexG9CQMspQWVMeta || {},
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/jobs/index.vue")
  },
  {
    name: "jobs-and-applications-models-jobs-filters.interface",
    path: "models/jobs-filters.interface",
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs-and-applications/models/jobs-filters.interface.ts")
  }
]
  },
  {
    name: "jobs-jobId",
    path: "/jobs/:jobId()",
    meta: _91jobId_93tCx0f9jd8bMeta || {},
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/jobs/[jobId].vue")
  },
  {
    name: "my-week-utils-push.helper",
    path: "/my-week/utils/push.helper",
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/my-week/utils/push.helper.ts")
  },
  {
    name: "not-allowed",
    path: "/not-allowed",
    meta: not_45allowed1brvBdTLRbMeta || {},
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/not-allowed.vue")
  },
  {
    name: "not-found",
    path: "/not-found",
    meta: not_45foundll24F94pkOMeta || {},
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/not-found.vue")
  },
  {
    name: "offline",
    path: "/offline",
    meta: offline93yG0VTps8Meta || {},
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/offline.vue")
  },
  {
    name: "person-personId-verify-verificationId",
    path: "/person/:personId()/verify/:verificationId()",
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/person/[personId]/verify/[verificationId].vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyFBFyU0X6RKMeta || {},
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/privacy-policy.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexxkqlAxAwZoMeta || {},
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/profile/index.vue")
  },
  {
    name: "register-personId-tenantId",
    path: "/register/:personId()/:tenantId?",
    meta: _91_91tenantId_93_93OCBYP4BcB3Meta || {},
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/register/[personId]/[[tenantId]].vue")
  },
  {
    name: "register-ensure-candidate",
    path: "/register/ensure-candidate",
    meta: indexx54pMwIErCMeta || {},
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/register/ensure-candidate/index.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: index1VLcMa7kBFMeta || {},
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/register/index.vue")
  },
  {
    name: "schedule",
    path: "/schedule",
    meta: indexAWzWVS25reMeta || {},
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/schedule/index.vue")
  },
  {
    name: "time-clock-timeClockId",
    path: "/time-clock/:timeClockId()",
    meta: _91timeClockId_938lyU2L7EbMMeta || {},
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/time-clock/[timeClockId].vue")
  },
  {
    name: "time-registration",
    path: "/time-registration",
    meta: indexiFCqK0Cqz5Meta || {},
    component: () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/pages/time-registration/index.vue")
  }
]