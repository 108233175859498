<script lang="ts" setup>
import SwiperInstance from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { usePersonTodoForm } from './composables/usePersonTodoForm';

// #region Globals
const { appImageBasePath, showPositions } = useBranding();
// #endregion

// #region Props & Emits
const props = defineProps({
   id: { type: String, default: null },
});

defineEmits<{
   (e: 'onClose'): void;
}>();
// #endregion

// #region swiper
const swiperInstance = ref<SwiperInstance | null>();
const activeIndex = computed(() => swiperInstance?.value?.activeIndex ?? 0);
// #endregion

// #region  person todo form
const { data: personTodo } = await useAuthFetch<PersonTodo>(PERSON_TODO_ENDPOINTS.GET(props.id), { lazy: false });
const { form, resources, person, tabs, error, confirmPersonTodo, uploadFiles } = usePersonTodoForm(personTodo);
// #endregion

// #region auto set communication
const { data: communicationPreferenceTypes } = useAuthFetch<Array<CommunicationPreferenceType>>(
   COMMUNICATION_PREFERENCE_TYPE_ENDPOINTS.GET(),
   cacheConfig,
);

function autoSetCommunication(communicationPreferenceTypes?: Array<CommunicationPreferenceType>) {
   if (!communicationPreferenceTypes?.length) return;

   form.communication_preferences = communicationPreferenceTypes?.map((cpt) => ({
      communication_preference_type_id: cpt.id,
      communication_preference_type: cpt,
   }));
}

watch(communicationPreferenceTypes, autoSetCommunication, { immediate: true });
// #endregion

// #region auto set job distance
const officialAddressCity = computed(() => form.official_address?.city);

function autoSetOfficialAddress(city?: City) {
   if (form.job_distances?.length || !city) return;

   form.job_distances = [
      {
         city_id: city.id,
         city,
         radius: 35,
      },
   ];
}

watch(officialAddressCity, autoSetOfficialAddress, { immediate: true });
// #endregion

// #region Submit
const showCloseModal = ref(false);
const saving = ref(false);

async function onClose() {
   try {
      saving.value = true;
      await uploadFiles();
      const { data } = (await form.submit()) as ApiResponse<Person>;
      form.setData({ ...data, profile_picture_url: undefined } as Partial<Person>);

      await confirmPersonTodo();
      if (!error.value) showCloseModal.value = true;

      useMyWeekStore().refreshMyWeek();
      useUserInfoStore().getUserInfo();
      if (showPositions.value) usePositionStore().refetchMyPositions();
   } catch (e: any) {
      const errors = e?.response?.data?.errors ?? {};
      const keys = Object.keys(errors);
      const questionIndex = tabs.value.findIndex((t) => t.sections.some((s) => s.questions.some((q) => keys.includes(q.key))));
      if (questionIndex !== -1) swiperInstance.value?.slideTo(questionIndex + 1);
   } finally {
      saving.value = false;
   }
}

// #endregion
</script>

<template>
   <ModalSide
      :max-height="'var(--nxt-modal-layer-1)'"
      :min-height="'30rem'"
      :height="'fit-content'"
      :saving="saving"
      :title="activeIndex > 0 ? tabs[swiperInstance!.activeIndex - 1].name : ''"
      :custom-close="true"
      :type="'clean'"
      :can-close="false"
      :on-return="() => swiperInstance?.slidePrev()"
      @on-close="onClose"
   >
      <div class="onboarding-modal">
         <Swiper class="onboarding-modal__swiper" :no-swiping="true" :allow-touch-move="false" @swiper="swiperInstance = $event">
            <SwiperSlide :key="'welcome'">
               <div class="onboarding-modal__tab -welcome">
                  <NuxtImg :src="`${appImageBasePath}/logo-dark.png`" :height="100" />
                  <div class="onboarding-modal__info">
                     <h3>
                        <span>{{ $t('onboarding.welcome', { firstName: person?.first_name }) }}</span>
                     </h3>

                     <p>
                        {{ $t('onboarding.description') }}
                     </p>
                  </div>
               </div>
            </SwiperSlide>
            <SwiperSlide v-for="(tab, tabIndex) in tabs" :key="tabIndex">
               <div class="onboarding-modal__tab">
                  <template v-for="section in tab.sections" :key="section.name">
                     <PersonTodoCustomSection v-if="section.isCustomSection && form" v-model="form" :section="section" />

                     <template v-else>
                        <PersonTodoQuestion
                           v-for="question in section.questions"
                           :key="question.key"
                           :model-value="getPropertyByPath(form, question.key)"
                           :question="question"
                           :resource="question.resource_endpoint ? resources[question.resource_endpoint] : null"
                           @update:model-value="updatePropertyByPath(form, question.key, $event)"
                        />
                     </template>
                  </template>
               </div>
            </SwiperSlide>
         </Swiper>
      </div>

      <PersonTodoOnboardingCloseModal v-if="showCloseModal" @on-close="$emit('onClose')" />

      <template #actions>
         <div class="onboarding-modal__actions">
            <ButtonMain v-if="activeIndex === 0" :disabled="saving" @on-click="swiperInstance?.slideNext()">
               {{ $t('onboarding.get-started') }}
            </ButtonMain>

            <template v-else>
               <div class="onboarding-modal__actions__group">
                  <ButtonLink
                     v-if="activeIndex > 0"
                     :disabled="saving"
                     :icon-name="'chevron-left'"
                     :icon-position="'left'"
                     @on-click="swiperInstance?.slidePrev()"
                  >
                     {{ $t('actions.previous') }}
                  </ButtonLink>

                  <ButtonMain v-if="activeIndex === tabs.length" :disabled="saving" @on-click="onClose">
                     {{ $t('actions.save') }}
                  </ButtonMain>

                  <ButtonMain
                     v-else
                     :disabled="saving"
                     :icon-name="'chevron-right'"
                     :icon-position="'right'"
                     @on-click="swiperInstance?.slideNext()"
                  >
                     {{ $t('actions.next') }}
                  </ButtonMain>
               </div>
            </template>
         </div>
      </template>
   </ModalSide>
</template>

<style lang="scss" scoped>
.onboarding-modal {
   height: 100%;
   width: 100%;
}

.onboarding-modal__swiper {
   height: 100%;
}

.onboarding-modal__tab {
   height: 100%;
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
   overflow: auto;

   &.-welcome {
      align-items: center;
      text-align: center;
      gap: var(--nxt-gutter-large);

      img {
         max-width: 80%;
         object-fit: contain;
      }
   }
}

.onboarding-modal__info {
   max-width: 80%;
   h3 {
      margin: 0;
      font-size: 1.5rem;
      line-height: 1.5;
   }
}

.onboarding-modal__actions {
   display: flex;
   gap: var(--nxt-gutter-small);
   width: 100%;
   padding: var(--nxt-gutter) 0;

   > * {
      white-space: nowrap;
   }
}

.onboarding-modal__actions__group {
   width: 100%;
   display: grid;
   grid-template-columns: 0.6fr 1.4fr;
   gap: var(--nxt-gutter-small);
}
</style>
