<script lang="ts" setup>
// #region Props & Emits
const emits = defineEmits<{ (e: 'onClose'): void }>();
const props = defineProps({
   title: { type: String, default: 'NxtPeople' },
   type: { type: String as PropType<AppStatus['type']>, default: 'info' },
   delay: { type: Number, default: null },
   canClose: { type: Boolean, default: true },
   bannerClickAction: { type: Function as PropType<() => void>, default: null },
});
// #endregion

// #region Banner Click
function bannerClick() {
   if (process.server) return;
   if (!props.bannerClickAction) return;

   props.bannerClickAction();
}
// #endregion

// #region close
const closeTimer = ref<NodeJS.Timeout | null>(null);
const delay = computed(() => props.delay);

function initTimer(newDelay?: number) {
   if (closeTimer.value) clearTimeout(closeTimer.value);

   if (!newDelay) return;
   if (!props.canClose) return;

   closeTimer.value = setTimeout(() => {
      emits('onClose');
   }, newDelay);
}

watch(delay, initTimer, { immediate: true });
// #endregion
</script>

<template>
   <div :class="['banner', type, { '-clickable': !!bannerClick }]" @click="bannerClick">
      <p class="banner__title" v-html="title"></p>

      <ButtonIcon
         v-if="canClose"
         :class="'banner__action'"
         :icon-name="'times'"
         :icon-label="$t('actions.close')"
         @on-click="emits('onClose')"
      />
   </div>
</template>

<style lang="scss" scoped>
.banner {
   width: 100%;
   padding: toRem(10);
   padding-top: calc(var(--device-padding-top) + toRem(10));

   display: flex;
   align-items: center;
   justify-content: flex-end;
   height: fit-content;
   min-height: toRem(40);
   position: relative;

   &.info {
      background-color: #baedf6;
      color: var(--nxt-dark);
   }

   &.success {
      background-color: #beefca;
      color: var(--nxt-dark);
   }

   &.error {
      background-color: #f2c0c4;
      color: var(--nxt-dark);
   }

   &.warning {
      background-color: #f6e7af;
      color: var(--nxt-dark);
   }

   &.-clickable {
      cursor: pointer;
   }
}

.banner__title {
   margin: 0;
   text-align: center;
   flex: 1;
   padding: 0 var(--nxt-gutter);
}
</style>
