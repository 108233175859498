import { tenantBrandings } from '~/config/tenant-brandings';

function setCSSProperties(cssProperties: Record<string, string>) {
   for (const [property, value] of Object.entries(cssProperties)) {
      document.documentElement.style.setProperty(property, value);
   }
}

function getFiles(locale: string, tenantKey: TenantKey) {
   return {
      termsAndConditionsUrl: tenantBrandings[tenantKey]?.files?.termsAndConditionsUrl?.(locale) ?? '',
      privacyPolicyUrl: tenantBrandings[tenantKey]?.files?.privacyPolicyUrl?.(locale) ?? '',
      cookiePolicyUrl: tenantBrandings[tenantKey]?.files?.cookiePolicyUrl?.(locale) ?? '',
   };
}

export const useBrandingStore = defineStore('branding', () => {
   const { appEnv, tenantKey: configTenantKey } = useRuntimeConfig().public;

   const branding = ref({
      tenantName: '',
      duoTone: false,
      fontUrl: '',
      bannerImage: '',
      showPositions: false,
      showDocuments: false,
      files: {
         termsAndConditionsUrl: '',
         privacyPolicyUrl: '',
         cookiePolicyUrl: '',
      },
   });

   const tenantKey = computed<TenantKey>(() => {
      if (appEnv === 'dev') return (configTenantKey as TenantKey) || 'nxt-plan';
      return getTenantKey(useRequestURL().hostname);
   });

   const appImageBasePath = computed(() => `${tenantKey.value}-icons`);

   function updateStyling([tenantKey, locale]: [TenantKey, string]) {
      const tenantStyle = tenantBrandings[tenantKey] || (tenantBrandings['nxt-plan'] as TenantStyle);

      branding.value = {
         tenantName: tenantStyle.tenantName,
         duoTone: tenantStyle.cssProperties['--nxt-secondary'] !== undefined,
         fontUrl: tenantStyle.fontUrl,
         bannerImage: tenantStyle.bannerImage,
         files: getFiles(locale, tenantKey),
         showPositions: tenantStyle.showPositions,
         showDocuments: tenantStyle.showDocuments,
      };

      setCSSProperties(tenantStyle.cssProperties);
   }

   updateStyling([tenantKey.value, useNuxtApp().$i18n.locale.value]);

   return { branding, tenantKey, appImageBasePath, updateStyling };
});
