<script lang="ts" setup>
const props = defineProps({
   backgroundColor: { type: String, default: 'var(--nxt-red)' },
   badgeContent: { type: [String, Number], default: '' },
});

const fontSize = computed(() => {
   const charCount = String(props.badgeContent).length;

   if (charCount <= 2) return '75%';
   if (charCount <= 3) return '60%';
   if (charCount <= 4) return '50%';
});
</script>

<template>
   <div :class="'badge'">
      {{ badgeContent }}
   </div>
</template>

<style lang="scss" scoped>
.badge {
   background-color: v-bind(backgroundColor);
   height: toRem(17);
   width: toRem(17);
   display: flex;
   justify-content: center;
   align-items: center;
   color: var(--nxt-white);
   border-radius: 50%;
   font-size: v-bind(fontSize);
   color: var(--nxt-white);
}
</style>
